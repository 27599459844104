<template lang="pug">
.h-100
  template(v-if="!loading && sale")
    top
    .main.d-flex.flex-column.justify-center.align-center.pb-10.px-4
      .text-center.my-5
        .text-h5.text-sm-h4.white--text
          .bebas-neue
            span VENDA N.{{ sale.cod }}
            span.mx-2.primary--text {{ sale.price }}
            span {{ sale.date }}
        .text-h4.text-sm-h3.primary--text(v-if="sale.name")
          .bebas-neue.text-uppercase {{ sale.name }}
      .qrcode(@click="copy", v-html="qrcode")
      v-btn.font-weight-bold.btn-copy(
        @click="copy",
        color="accent",
        depressed,
        x-large
      )
        v-icon(small, left) fas fa-copy
        span Copiar Código
      v-snackbar(v-model="copied", :timeout="2000", max-width="80px")
        div Código copiado
        template(#action="{ attrs }")
          v-btn(
            color="white",
            small,
            icon,
            v-bind="attrs",
            @click="copied = false"
          )
            v-icon(small) fas fa-times
    how-to
    bottom
  v-sheet.d-flex.justify-center.align-center(v-else, height="100%")
    v-progress-circular(:size="50", color="secondary", indeterminate)
</template>

<script>
import QRCode from "qrcode";
import Top from "./Top";
import Bottom from "./Bottom";
import HowTo from "./HowTo";

export default {
  data: () => ({
    qrcode: "",
    loading: true,
    sale: null,
    copied: false,
  }),
  computed: {
    cod() {
      return this.$route.params.cod;
    },
    text() {
      return this.sale && this.sale.qrcode;
    },
  },
  methods: {
    generate(text) {
      if (!text) return;

      QRCode.toString(text, { type: "svg" }, (err, qrcode) => {
        if (err) throw err;
        this.qrcode = qrcode;
      });
    },
    copy() {
      navigator.clipboard
        .writeText(this.text)
        .then(
          () =>
            (this.copied = true) && window.console.log("COPY -> " + this.text)
        );
    },
  },
  created() {
    fetch(
      `https://api-mgspa.mgpapelaria.com.br/api/v1/pix/cob/${this.cod}/detalhes`
    )
      .then((r) => r.json())
      .then(
        ({
          data: { status, valororiginal, pessoa, codnegocio, criacao, qrcode },
        }) => {
          if (status === "CONCLUIDA" || !qrcode) throw new Error();

          this.sale = {
            price: new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(valororiginal),
            name: pessoa,
            cod: codnegocio,
            date: new Intl.DateTimeFormat("pt-BR").format(
              new Date(criacao.replace(" ", "T"))
            ),
            qrcode,
          };

          this.loading = false;
        }
      )
      .catch((e) => {
        window.console.error(e);
        this.$router.replace("/error404");
      });

    this.generate(this.text);
  },
  watch: {
    text(v) {
      this.generate(v);
    },
  },
  components: {
    Top,
    Bottom,
    HowTo,
  },
};
</script>

<style>
.v-application {
  font-family: "Nexa Light", sans-serifs !important;
}

@font-face {
  font-family: "Nexa Bold";
  src: url("/font/Nexa Bold.otf");
}
@font-face {
  font-family: "Nexa Light";
  src: url("/font/Nexa Light.otf");
}

.bebas-neue {
  font-family: "Bebas Neue", cursive !important;
}
.nexa-bold {
  font-family: "Nexa Bold", sans-serifs !important;
}
.nexa-light {
  font-family: "Nexa Light", sans-serifs !important;
}

.main {
  width: 100%;
  background: linear-gradient(
    to bottom,
    var(--v-secondary-base) 40%,
    #ede7f6 40%
  ) !important;
}
svg {
  width: 500px;
  max-width: 100%;
}
.qrcode {
  border: 1px solid #ccc;
  margin-bottom: -6px;
  cursor: copy;
}
.btn-copy {
  width: 500px !important;
  max-width: 100%;
}
.h-100 {
  height: 100%;
}
.v-snack__wrapper {
  min-width: 175px !important;
}
</style>
