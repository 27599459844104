import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#ffec01",
        secondary: "#3f3fc3",
        accent: "#fe0000"
      }
    }
  }
});
