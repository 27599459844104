import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100"},[(!_vm.loading && _vm.sale)?[_c('top'),_c('div',{staticClass:"main d-flex flex-column justify-center align-center pb-10 px-4"},[_c('div',{staticClass:"text-center my-5"},[_c('div',{staticClass:"text-h5 text-sm-h4 white--text"},[_c('div',{staticClass:"bebas-neue"},[_c('span',[_vm._v("VENDA N."+_vm._s(_vm.sale.cod))]),_c('span',{staticClass:"mx-2 primary--text"},[_vm._v(_vm._s(_vm.sale.price))]),_c('span',[_vm._v(_vm._s(_vm.sale.date))])])]),(_vm.sale.name)?_c('div',{staticClass:"text-h4 text-sm-h3 primary--text"},[_c('div',{staticClass:"bebas-neue text-uppercase"},[_vm._v(_vm._s(_vm.sale.name))])]):_vm._e()]),_c('div',{staticClass:"qrcode",domProps:{"innerHTML":_vm._s(_vm.qrcode)},on:{"click":_vm.copy}}),_c(VBtn,{staticClass:"font-weight-bold btn-copy",attrs:{"color":"accent","depressed":"","x-large":""},on:{"click":_vm.copy}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("fas fa-copy")]),_c('span',[_vm._v("Copiar Código")])],1),_c(VSnackbar,{attrs:{"timeout":2000,"max-width":"80px"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"white","small":"","icon":""},on:{"click":function($event){_vm.copied = false}}},'v-btn',attrs,false),[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)]}}],null,false,16943772),model:{value:(_vm.copied),callback:function ($$v) {_vm.copied=$$v},expression:"copied"}},[_c('div',[_vm._v("Código copiado")])])],1),_c('how-to'),_c('bottom')]:_c(VSheet,{staticClass:"d-flex justify-center align-center",attrs:{"height":"100%"}},[_c(VProgressCircular,{attrs:{"size":50,"color":"secondary","indeterminate":""}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }